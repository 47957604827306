
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiHelpMenuGetMenu, apiHelpContentSetData, apiHelpContentUpdateData, apiHelpContentGetData } from '@/api/instructions'
import MaterialSelect from '@/components/material-select/index.vue'
import LsEditor from '@/components/editor.vue'
import { PageMode } from '@/utils/type'
import { Getter } from "vuex-class"

@Component({
    components: {
        LsEditor,
        MaterialSelect
    }
})
export default class ArticleEdit extends Vue {
    @Getter('lang') lang!: Array<string>
    /** S Data **/
    mode: string = PageMode.ADD // 当前页面: add-添加角色 edit-编辑角色
    defaultLanguage: string = 'zh_cn'
    // 分类列表
    categoryList: Array<object> = []

    // 表单数据
    form: any = {
        id: 0, // 当前编辑用户的身份ID
        title: '', // 标题
        menu_id: '', // 分类id
        synopsis: '', // 简介
        img: '', // 封面图片
        content: '', // 内容
        sort: 0, // 排序值
        status: 1 // 显示: 0-隐藏 1-显示(默认)
    }

    type = 2

    // 表单校验
    rules: object = {
        title: [
            {
                required: true,
                message: '请输入文章标题',
                trigger: 'blur'
            }
        ],
        menu_id: [
            {
                required: true,
                message: '请选择文章分类',
                trigger: 'change'
            }
        ],
        // synopsis: [{
        // 	required: true,
        // 	message: '请输入文章简介',
        // 	trigger: 'blur'
        // }],
        // sort: [{
        // 	required: true,
        // 	message: '请输入排序',
        // 	trigger: 'blur'
        // }],
        content: [
            {
                required: true,
                message: '请输入文章标题',
                trigger: 'blur'
            }
        ]
    }

    /** E Data **/

    processLabel(data: any) {
        function isJSONString(str: string): boolean {
            try {
                JSON.parse(str);
                return true;
            } catch (error) {
                return false;
            }
        }
        return data.map((item: any) => {
            if (isJSONString(item.label)) {
                const parsedData = JSON.parse(item.label);
                if (parsedData && parsedData.zh_cn) {
                    item.label = parsedData.zh_cn;
                }
            }

            if (item.children) {
                item.children.forEach((children: any) => {
                    if (isJSONString(children.label)) {
                        const parsedSonData = JSON.parse(children.label);
                        if (parsedSonData && parsedSonData.zh_cn) {
                            children.label = parsedSonData.zh_cn;
                        }
                    }

                    if (children.children) {
                        children.children.forEach((nestedSon: any) => {
                            if (isJSONString(nestedSon.label)) {
                                const parsedNestedSonData = JSON.parse(nestedSon.label);
                                if (parsedNestedSonData && parsedNestedSonData.zh_cn) {
                                    nestedSon.label = parsedNestedSonData.zh_cn;
                                }
                            }
                        });
                    }
                });
            }

            return item;
        });
    }


    processData(key: string) {
        if (typeof this.form[key] === 'string' && this.form[key].includes('{') && this.form[key].includes('}')) {
            if (this.lang.length > 1) {
                this.form[key] = JSON.parse(this.form[key])
            } else {
                let defaultLang = this.lang.filter(v => v === 'zh_cn').length > 0 ? 'zh_cn' : this.lang[0];
                this.form[key] = JSON.parse(this.form[key])[defaultLang]
            }
        } else {
            if (this.lang.length > 1) {
                let defaultLang = this.lang.filter(v => v === 'zh_cn').length > 0 ? 'zh_cn' : this.lang[0];
                let multiLanguage: any = {};
                for (const item of this.lang) {
                    this.$set(multiLanguage, item, '');
                }
                if (defaultLang) multiLanguage[defaultLang] = this.form[key];
                this.form[key] = multiLanguage as any;
            }
        }
    }

    /** S Methods **/
    switchLanguage(e: any) {
        this.defaultLanguage = e;
    }
    // 提交表单
    onSubmit(formName: string) {
        const refs = this.$refs[formName] as HTMLFormElement
        if (this.lang.length > 1) {
            if (this.checkEmptyValues(this.form.title)) return this.$message.error('请输入完整的标题');
            if (this.checkEmptyValues(this.form.content)) return this.$message.error('请输入完整的内容');
        }
        refs.validate((valid: boolean): void => {
            if (!valid) {
                return
            }

            // 请求发送
            switch (this.mode) {
                case PageMode.ADD:
                    return this.handleArticleAdd()
                case PageMode.EDIT:
                    return this.handleArticleEdit()
            }
        })
    }


    // 检查是否包含空字符串
    checkEmptyValues(obj: any) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === '') {
                return true;
            }
        }
        return false;
    }

    // 添加帮助文章
    handleArticleAdd() {
        let form = JSON.parse(JSON.stringify(this.form));
        apiHelpContentSetData(form).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    // 编辑帮助文章
    handleArticleEdit() {
        let form = JSON.parse(JSON.stringify(this.form));
        apiHelpContentUpdateData(form).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    // 初始化表单数据: 编辑
    initArticleEdit() {
        apiHelpContentGetData({
            id: this.form.id
        }).then(res => {
            Object.keys(res).map(item => {
                this.$set(this.form, item, res[item])
            });
        })
    }

    // 初始化文章分类数据
    initCategoryLists() {
        apiHelpMenuGetMenu({
            type: this.type
        }).then((res:any) => {
            let categoryList = res?.lists
            categoryList = this.processLabel(categoryList);
            this.categoryList = categoryList;
        })
    }

    processInput(input: any) {
        if (typeof input === 'string' && input.includes('{') && input.includes('}')) {
            try {
                const obj = JSON.parse(input);
                return Object.values(obj).join(',');
            } catch (error) {
                return input;
            }
        } else if (typeof input === 'string') {
            return input
        } else {
            return 'Invalid input';
        }
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        if (query.mode) {
            this.mode = query.mode
        }

        this.initCategoryLists()
        this.processData('title');
        this.processData('content');

        if (this.mode === PageMode.EDIT) {
            this.form.id = query.id * 1
            this.initArticleEdit()
        }
    }

    /** E Life Cycle **/
}
